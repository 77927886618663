<template>
  <div class="home">
    <v-layout row wrap>
      <v-flex xs12 md12 lg12 class="mb-5">
        <h1 class="f-alfa primary--text">
          <router-link to="/">Dashboard</router-link>  / Add Helicopter
        </h1>
      </v-flex>
      <v-flex xs12 lg8 class="mb-10">
        <v-simple-table class="mg-10">
          <tbody>
            <tr>
              <td class="fs-12 primary--text">Engine Type</td>
              <td class="fs-12">
                <v-radio-group v-model="engine">
                  <v-radio label="Single" value="single"></v-radio>
                  <v-radio label="Twin" value="twin"></v-radio>
                </v-radio-group>
              </td>
            </tr>
            <tr>
              <td class="fs-12 primary--text">Year of Reg.</td>
              <td class="fs-12">
                <v-select
                  :items="years"
                  single-line
                  outlined
                  label="Year"
                ></v-select>
              </td>
            </tr>
            <tr>
              <td class="fs-12 primary--text">Date of UAE Reg.</td>
              <td class="fs-12">
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="true"
                  transition="scale-transition"
                  offset-y
                  min-widtd="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date_uae_registration"
                      label="Date"
                      single-line
                      outlined
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date_uae_registration"
                    @input="menu2 = false"
                  ></v-date-picker>
                </v-menu>
              </td>
            </tr>
            <tr>
              <td class="fs-12 primary--text">Renewal Date</td>
              <td class="fs-12">
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="true"
                  transition="scale-transition"
                  offset-y
                  min-widtd="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="registration_renewal_date"
                      label="Date"
                      single-line
                      outlined
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="registration_renewal_date"
                    @input="menu2 = false"
                  ></v-date-picker>
                </v-menu>
              </td>
            </tr>
            <tr>
              <td class="fs-12 primary--text">Lease Type</td>
              <td class="fs-12">
                <v-radio-group v-model="lease_type">
                  <v-radio label="Dry" value="Dry"></v-radio>
                  <v-radio label="Wet" value="Wet"></v-radio>
                </v-radio-group>
              </td>
            </tr>
            <tr>
              <td class="fs-12 primary--text">Total Lease Value</td>
              <td class="fs-12">
                <div class="d-flex">
                    <v-text-field single-line outlined v-model="total_lease_value" label="Lease Value"></v-text-field>
                    <v-select :items="currencies" single-line outlined style="widtd: 200px;" label="Currency"></v-select>
                </div>
              </td>
            </tr>
            <tr>
              <td class="fs-12 primary--text">Monthly Lease Due Date</td>
              <td class="fs-12">
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="true"
                  transition="scale-transition"
                  offset-y
                  min-widtd="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="monthly_lease_due_date"
                      label="Date"
                      single-line
                      outlined
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="monthly_lease_due_date"
                    @input="menu2 = false"
                  ></v-date-picker>
                </v-menu>
              </td>
            </tr>
            <tr>
              <td class="fs-12 primary--text">Rate / Hr.</td>
              <td class="fs-12">
                <div class="d-flex">
                    <v-text-field single-line outlined v-model="rate_per_hour" label="Rate"></v-text-field>
                    <v-select :items="currencies" single-line outlined style="widtd: 200px;" label="Currency"></v-select>
                </div>
              </td>
            </tr>
            <tr>
                <td></td>
                <td><v-btn @click="saveCopter" class="primary black--text" x-large block>Submit</v-btn></td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import { BASE_URL } from '../../config';
import Axios from 'axios'
export default {
  data() {
    return {
      engine: "",
      year_of_registration: "2023",
      years: [
        "2023",
        "2022",
        "2021",
        "2020",
        "2019",
        "2018",
        "2017",
        "2016",
        "2015",
      ],
      date_uae_registration: "",
      registration_renewal_date: "",
      lease_type: "",
      lease_currency: "USD",
      total_lease_value: "",
      monthly_lease_due_date: "",
      rate_per_hour: "",
      currencies : ["USD","AED","EURO","GBP"],
    };
  },
  methods: {
    generateUniqueCode() {
      let code = "";
      while (code.length < 6) {
        const digit = Matd.floor(Matd.random() * 10); // Generates a random digit (0-9)
        code += digit;
      }
      return code;
    },
    async saveCopter() {
        alert('hi')
      let code = "";
      if (this.engine == "single") {
        code += "AC-S-";
      } else {
        code += "AC-T-";
      }
      let uniqueCode = this.generateUniqueCode();
      code += uniqueCode;
      let url = BASE_URL + "/helicopter";
      let payload = {
        code: code,
        engine: this.engine,
        year_of_registration: this.year_of_registration,
        date_uae_registration: this.date_uae_registration,
        registration_renewal_date: this.registration_renewal_date,
        lease_type: this.lease_type,
        lease_currency: this.lease_currency,
        total_lease_value: this.total_lease_value,
        monthly_lease_due_date: this.monthly_lease_due_date,
        rate_per_hour: this.rate_per_hour,
      };
      let { data } = await Axios.post(url, payload);
      this.$router.push("/list-helicopters");
    },
  },
};
</script>

<style scoped>
.fs-12 {
  font-size: 12pt !important;
}
td{
    padding: 20px !important;
}
</style>