import { render, staticRenderFns } from "./AddHelicopter.vue?vue&type=template&id=752bbc8d&scoped=true"
import script from "./AddHelicopter.vue?vue&type=script&lang=js"
export * from "./AddHelicopter.vue?vue&type=script&lang=js"
import style0 from "./AddHelicopter.vue?vue&type=style&index=0&id=752bbc8d&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "752bbc8d",
  null
  
)

export default component.exports